@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables */
:root {
  --primary-font: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  --primary-color: #83A7AF;
  --background-color:#64919B

  }


/* Resets */
html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('../public/annie-spratt-xvU-X0GV9-o-unsplash.jpg');   */
  /* background-color: var(--background-color); */

}


/* Global Classes */
.text-center {
  text-align: center;
}

/* Custom Styles */







